import React, { useState } from 'react';
import good_banana from "../assets/good_banana.png"
import rotten_banana from "../assets/rotten_banana.png"
import 'animate.css/animate.css' // npm installed css animation library
import Bananas from './Bananas';

const Username = ({ username, idx, reset, setReset }) => {

    // const [hasFeedback, setHasFeedback] = useState(false);
    // const [resetSelf, setResetSelf] = useState(reset);

    const sendFeedback = () => {
        console.log("in banana trigger")
        username.feedback.given = true;
    }

    console.log("Feedback status of banana: ", idx, " is ", username.feedback.given)
    

    return (
        <>

            <tr>
                <td className="align-middle">
                    {username.content}
                </td>
                <Bananas
                    username={username}
                    sendFeedback={sendFeedback}
                    reset={reset}
                    setReset={setReset}
                    idx={idx}
                >
                </Bananas>
            </tr>


        </>
    )
}

export default Username;