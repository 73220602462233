import React, { useState } from 'react';
import './App.css';
import NameForm from './components/NameForm';
import UserNamesTable from './components/UserNamesTable';
import banomnom from './assets/Banomnom.png';
import fullname from './assets/full_webname_sm.png'
import websiteName from './assets/website-name.png'
import coffee from './assets/buy-me-coffee.png'
import banomnomText from './assets/banomnom_text.png'
import subtitle from './assets/subtitle_webname.png'


function App() {

  const [usernames, setUsernames] = useState([])
  const [reset, setReset] = useState(true)

  return (
    <>
        <div className="banana-wrap">
          <div className='header'>
            <div className="logo-bar">
              <img src={banomnom} alt="Banomnom Logo" className='logo' />
              <img src={fullname} alt="Banomnom, Generate memorable usernames" className='webname-full' />
              <div className='sm-device'>
                <img src={banomnomText} alt="Banomnom, Generate memorable usernames" className='webname-title' />
                <img src={subtitle} alt="Banomnom, Generate memorable usernames" className='webname-subtitle' />
              </div>
            </div>
          </div>
        </div>
        <main>
          <div className="main-container">

            <NameForm 
              setUsernames={setUsernames} 
              setReset={setReset}
/>
            
            <UserNamesTable 
              usernames={usernames} 
              reset={reset} 
              setReset={setReset}
/>

            <div className="text-center">
              <p>If we made you laugh, would you buy us a coffee?</p>
              <a href="https://www.buymeacoffee.com/banomnom"><img src={coffee} alt="buy me a coffee" /></a>
            </div>
          </div>
        </main>
        <footer>
          <div className="container-fluid mt-4" style={{ backgroundColor: '#ffe213' }}>
            <div className="container">
              <h3 className="text-left">Contact Us</h3>
              <p className="text-body-secondary">If you want to grab a banana (or coffee) with us, drop us a note at info@menudb.net</p>
            </div>
          </div>
        </footer>


    </>
  );
}

export default App;