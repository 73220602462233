import React, { useState } from 'react';
import good_banana from "../assets/good_banana.png"
import rotten_banana from "../assets/rotten_banana.png"
import 'animate.css' // npm installed css animation library

const Bananas = ({username, sendFeedback, reset, setReset, idx}) => {

    const [liked, setLiked] = useState(false)
    const [feedbackWasGiven, setFeedbackWasGiven] = useState(false)

    const reaction = (isLike) => {
        username.feedback.given = true;
        username.feedback.liked = isLike;

        setFeedbackWasGiven(username.feedback.given);
        setLiked(username.feedback.liked);
        
        sendFeedback();
        // TODO: send to DB
    }
    console.log(`Feedback given status in BANANAS for idx ${idx}: ${username.feedback.given}`, )
    const computedClassName = username.feedback.given ? 'feedbackSent':'bananas';
    let computedBananaClass = username.feedback.given ? 'animate__animated animate__bounce': 'banana';

    console.log(computedBananaClass)
    console.log(computedClassName)

    return (
        <>
            <td className={"align-middle " + computedClassName}>
                <img className={username.feedback.given&&username.feedback.liked?computedBananaClass:'banana'} id={"like"+idx} src={good_banana} alt="Good Banana" onClick={()=>{reaction(true)}}/>
                <img className={username.feedback.given&&!username.feedback.liked?computedBananaClass:'banana'} id={"dislike"+idx} src={rotten_banana} alt="Rotten Banana" onClick={()=>{reaction(false)}}/>
                <p className="sent unsent">Thank you for your feedback!</p>
            </td>
        </>
    );}

export default Bananas;