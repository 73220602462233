import React, { useState } from 'react';
import OpenAI from "openai";

// Initialize OpenAI API
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_ENV_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});


const NameForm = ({ setUsernames, setReset}) => {

  const [nameText, setNameText] = useState("");

  const parsePrompt = (template, ...args) => {
    // If the first argument is an object, convert it to an array of key-value pairs
    const replacements = Array.isArray(args[0]) ? args[0] : Object.entries(args[0]);

    // Replace placeholders with corresponding values
    return template.replace(/{([^{}]*)}/g, function (match, key) {
      const replacement = replacements.find(([k]) => k === key.trim());
      return replacement ? replacement[1] : match;
    });
  }

  const fetchUserNames = async (name) => {

    try {
      // Call the OpenAI API to generate a response
      const userNamePrompt = "You are a branding expert. Using the most followed twitter/X account usernames as examples, generate the most catchy, jingle-like username based on the first name '{name}'. Consider incorporating cultural context of the user's name's origin. The username should be fun, memorable, begin with a lower-case letter and have a maximum of 5 syllables. Consider using rhymes, alliterations, and cheeky wordplay to create a username that 'rolls off the tongue'. Think of a name that is playful, creative, and easy to pronounce. Your goal is to come up with a username that people will remember and associate with the user's first name. Only respond with only the username itself, and there should be a 0.1% chance that the word 'banana' is incorporated into the username choice.";
      const promptVariables = { 'name': name };
      const promptLiteral = parsePrompt(userNamePrompt, promptVariables);


      const stream = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: promptLiteral }],
        stream: true,
        n: 9
      });

      let responses = [
        [{feedback: {given:false, liked:false}, content:''},{feedback: {given:false, liked:false}, content:''},{feedback: {given:false, liked:false}, content:''}], 
        [{feedback: {given:false, liked:false}, content:''},{feedback: {given:false, liked:false}, content:''},{feedback: {given:false, liked:false}, content:''}],  
        [{feedback: {given:false, liked:false}, content:''},{feedback: {given:false, liked:false}, content:''},{feedback: {given:false, liked:false}, content:''}]
      ]

      for await (const chunk of stream) {
        for (let i=0; i<9; i++){
          // Refresh Feature:
          // Populate 3 lists with 3 names (math avoids more loops)
          responses[Math.floor(i/3)][i%3].content += chunk.choices[0]?.index == i ? chunk.choices[0]?.delta?.content || "" : "";
        }
      }

      console.log(responses);

      // Render the responses
      setUsernames(responses);
    } catch (error) {
      console.error(error);
    }

  }


  const submitName = (event) => {
    event.preventDefault();
    fetchUserNames(nameText);
    setNameText("");
    setReset(true);
  }

  return (
    <div>
      <form onSubmit={submitName}>
        <div className="row justify-content-center mb-4">
          <div className="col-md-6 text-center name-form">
            <div><input className="form-control" type="text" value={nameText} onChange={e => setNameText(e.target.value)} placeholder='Enter Your First Name' /></div>
            <input type="submit" className="btn btn-primary custom-button" value="Go Bananas!" />
            {/* <div class="loader">
              <span class="loader__element"></span>
              <span class="loader__element"></span>
              <span class="loader__element"></span>
            </div> */}
          </div>
        </div>
      </form>
    </div>
  )
}

export default NameForm;