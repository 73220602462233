import React, { useState } from 'react';
import Username from './Username';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserNamesTable = ({usernames, reset, setReset}) => {
    const [listIndex, setListIndex] = useState(0)
    console.log("INSIDE TABLE: ", usernames)

    const refresh = () => {
        setListIndex(listIndex < 2? listIndex+1 : 0);
        setReset(true)
    }
    console.log("INDEX -- ", listIndex)
    return (
        <div className='table-wrapper'>
        <h2 onClick={refresh}><FontAwesomeIcon icon={faRotateRight} /></h2>
        <table className="col-md-8 text-center mb-4 table">
                <thead className="thead-light">
                    <tr>
                        <th>Usernames</th>
                        <th>Feedback</th>
                    </tr>
                </thead>
                <tbody>
                    {usernames[listIndex] !== undefined ? usernames[listIndex].map((username, idx) =>
                        <Username username={username} idx={idx} key={idx} reset={reset} setReset={setReset}/>
                    ): ""}
                </tbody>
        </table>
        </div>
    )
}

export default UserNamesTable;